
import { useContext, useState } from "react";

import styled from 'styled-components';
import AppContext from "../contexts/AppContext";


const NavContainer = styled.div`
    position: fixed;
    z-index: 2;
    top: 0;
    right: 0;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 10vh;
    color: #e3e3e3;
    overflow: hidden;
    size: 1.4rem;

    @media (max-width:576px){
        display: None;
    };

`;

const NavSectionLeft = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 5%;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    height: 100%;
    min-height: 10vh;
`;

const NavSectionRight = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 50%;
    height: 100%;
    min-height: 10vh;
`;

const NavSectionHeading = styled.h3`
    color: black;
`;

const NavSectionLink = styled.div`
    height: 100%;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 10vh;
`;

const NavMobileContainer = styled.div`
    position: fixed;
    z-index: 2;
    top: 0;
    right: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 10vh;
    color: #e3e3e3;
    size: 1.4rem;

    @media (min-width:576px){
        display: None;
    };
`;

const NavMobileHeading = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    min-height: 10vh;
`;

const NavMobileLink = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 10vh;
    text-align: center;
    display: ${props => props.navActive ? "inline-block" : "None"}
`;

const NavMobileIcon = styled.h3`
    font-size: 1.5rem;
    color: ${props => props.navActive ? "black" : "#e3e3e3"};
`;


const NavSectionLinkText = styled.h5`
    color: ${props => props.currentPage ? "#000000" : "#e3e3e3"};
`

const Nav = () => {
    const [ navActive, setNavActive ] = useState(false);
    const { currentPage, updateCurrentPage } = useContext(AppContext);

    return (
        <>
            <NavContainer>
                <NavSectionLeft>
                    <NavSectionHeading>Emma + Zachary</NavSectionHeading>
                </NavSectionLeft>
                <NavSectionRight>
                    <NavSectionLink onClick={() => updateCurrentPage("our-story") }>
                        <NavSectionLinkText currentPage={currentPage === "our-story"}>Our Story</NavSectionLinkText>
                    </NavSectionLink>
                    <NavSectionLink onClick={() => updateCurrentPage("gallery") }>
                        <NavSectionLinkText currentPage={currentPage === "gallery"}>Gallery</NavSectionLinkText>
                    </NavSectionLink>
                    <NavSectionLink onClick={() => updateCurrentPage("gifts") }>
                        <NavSectionLinkText currentPage={currentPage === "gifts"}>Gifts</NavSectionLinkText>
                    </NavSectionLink>
                    <NavSectionLink onClick={() => updateCurrentPage("event") }>
                        <NavSectionLinkText currentPage={currentPage === "event"}>RSVP</NavSectionLinkText>
                    </NavSectionLink>
                </NavSectionRight>
            </NavContainer>
            <NavMobileContainer>
                <NavMobileHeading>
                    <NavSectionHeading>Emma + Zachary</NavSectionHeading>
                    <NavMobileIcon navActive={navActive} onClick={() => setNavActive(!navActive)}>☰</NavMobileIcon>
                </NavMobileHeading>
                <NavMobileLink navActive={navActive} onClick={() => updateCurrentPage("our-story") }>
                    <NavSectionLinkText currentPage={currentPage === "our-story"}>Our Story</NavSectionLinkText>
                </NavMobileLink>
                <NavMobileLink navActive={navActive} onClick={() => updateCurrentPage("gallery") }>
                    <NavSectionLinkText currentPage={currentPage === "gallery"}>Gallery</NavSectionLinkText>
                </NavMobileLink>
                <NavMobileLink navActive={navActive} onClick={() => updateCurrentPage("gifts") }>
                    <NavSectionLinkText currentPage={currentPage === "gifts"}>Gifts</NavSectionLinkText>
                </NavMobileLink>
                <NavMobileLink navActive={navActive} onClick={() => updateCurrentPage("event") }>
                    <NavSectionLinkText currentPage={currentPage === "event"}>RSVP</NavSectionLinkText>
                </NavMobileLink>
            </NavMobileContainer>   
        </>
    );
}

export default Nav;