import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';

import Button from 'react-bootstrap/Button';
import { useContext } from 'react';
import AppContext from '../contexts/AppContext';



const EventHeader = styled.div`
    display: flex;
    width: 90%;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    @media (max-width:807px){
        justify-content: center;
        align-items: center;
    };
    font-size: 2vw;
`;

const ExitButton = styled.div`
    color: red;
    text-align: left;
    width: 100%;
`;

const EventHeaderContent = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 50%;
    color: #3e3d3e;


    @media (max-width:807px){
        width: 90%;
        padding: 0;
    };


    h2 {
        font-size: 2rem;
        color: #3e3e3e;
    }

    h3 {
        font-size: 1.4rem;
    }

    h4 {
        font-size: 1.2rem;
    }

    p {
        font-size: 2vw;
    }
`;

const EventDressCode = styled.div`
    padding-top: 2rem;
`;

const EventHeaderDetails = styled.div`
    display: flex;
    flex-direction: column;
`;

const EventDateContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;



const EventHeaderMap = styled.div`
    width: 40vw;
    height: 40vw;
    min-width: 323px;
    min-height: 323px;

    @media (max-width:807px){
        width: 90w;
        padding: 0;
    };
`;


const Marker = styled.div`
    cursor: pointer;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50% 50% 50% 0;
    background: #3e3e3e;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
`;

const EventDetailsHeading = styled.h2`
    margin: 2vh 0;
`;

const EventDetails = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 80%;
    margin: 5vh 0;
    text-align: center;

    @media (max-width:807px){
        flex-wrap: wrap;
        width: 90%;
        margin: 0;
    };
`;

const EventDetailsContent = styled.div`
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 2.5%;
    font-size: 0.8rem

    @media (max-width:807px){
        width: 80%;
        margin: 0;
    };
`;

const EventDetailsDetail = styled.div`
    display: flex;
    flex-direction: row;
    align-items: space-between;
    justify-content: space-between;
    font-size: 0.8rem
    width: 30%;
`;


const EventFAQ = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    width: 90%;
    margin: 5vh 0;
`;


const EventFAQDetails = styled.div`
    width: 40%;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 2%;
    justify-content: flex-start;

    @media (max-width:807px){
        text-align: center;
        width: 90%;
        margin: 0;
    };
`;


const Event = ({ event, showRSVPForm }) => {
    const { updateEvent } = useContext(AppContext); 

    return (
        <>
            <EventHeader>
                <ExitButton onClick={() => updateEvent(null)}><p>{"< Exit Event"}</p></ExitButton>
                <EventHeaderContent>
                    <h2>{event.name}</h2>
                    <EventHeaderDetails>
                        <EventDateContainer>
                            <h3>{event.location.name}</h3>
                            <h3>{event.date}</h3>
                        </EventDateContainer>
                        <h4>{event.time}</h4>
                    </EventHeaderDetails>
                    <h4>{event.location.address}</h4>
                    <EventDressCode>
                        <p>{event.dress_code}</p>
                    </EventDressCode>
                    {/* <p>* Ability to RSVP will be available on August 22.</p> */}
                    {event.rsvp_enabled ? <Button onClick={showRSVPForm} variant="secondary">RSVP</Button> : null}
                </EventHeaderContent>
                <EventHeaderMap>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyDOGEYdMUey4jwgaRz9xpvfis2s5Tt-P00" }}
                            defaultZoom={11}
                            center={{ lat: event.location.latitude, lng: event.location.longitude}}
                        >
                                <Marker lat={event.location.latitude} lng={event.location.longitude} />
                                    
                        </GoogleMapReact>
                </EventHeaderMap>
            </EventHeader>
            {event.accommodations.length ? (
                <>
                <EventDetailsHeading>Accommodations</EventDetailsHeading>
                <EventDetails>
                    {event.accommodations.map(accomdation => {
                        return (
                            <EventDetailsContent>
                                <h3>{accomdation.name}</h3>
                                <p>{accomdation.description}</p>

                                <p><b>{accomdation.phone_numer}</b></p>
                                <p><b>{accomdation.address}</b></p>
                            </EventDetailsContent>
                        );
                    })}
                </EventDetails>
                </>
            ) : null}
            {event.transportation.length ? (
                <>
                <EventDetailsHeading>Transportation</EventDetailsHeading>
                <EventDetails>
                    {event.transportation.map(transportation => {
                        return (
                            <EventDetailsContent>
                                <h3>{transportation.name}</h3>
                                <p>{transportation.description}</p>
                            </EventDetailsContent>
                        );
                    })}
                </EventDetails>
                </>
            ) : null}
            <EventDetailsHeading>FAQ</EventDetailsHeading>
            <EventFAQ>
                {event.faqs.map(faq => {
                    return (
                        <EventFAQDetails>
                            <h3>{faq.question}</h3>
                            <p>{faq.answer}</p>
                        </EventFAQDetails>
                    );
                })}
            </EventFAQ>
        </>
    );
}

export default Event;