import { useContext, useEffect, useState } from "react";
import styled from 'styled-components';
import AppContext from "../contexts/AppContext";


import Nav from "../components/Nav";
import EventForm from "../forms/EventForm";
import RSVPForm from "../forms/RSVPForm";
import Event from "../components/Event";

import config from "../config/config";

const backgroundImage = require(`../images/output2.png`);

const PAGE = "event";
const base_url = config.base_url;


const EventPageContainer = styled.div`
    margin-top: 10vh;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(${props => props.image});
    background-size: cover;
    background-repeat: none;
    width: 100%;
    width: 100%;
    height: 100%;
`;

const EventPage = () => {
    const [showRSVPForm, setShowRSVPForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const { currentPage, updateCurrentPage, event, updateEvent } = useContext(AppContext);

    useEffect(() => {
        if (!currentPage) {
            updateCurrentPage(PAGE);
        }
    }, []);

    const onSuccess = () => {
        setIsSuccess(true);
        setTimeout(() => {
            setIsSuccess(false);
            setShowRSVPForm(false);
        }, 5000);
    }

    const handleRSVP = (rsvp, eventName) => {
        const errors = {};
        let error = false;
        if (!rsvp.name) {
            errors["nameError"] = "Name is Required"
            error = true
        }
        if (!rsvp.email) {
            errors["emailError"] = "Email is Required"
            error = true
        }
        if (!rsvp.phone) {
            errors["phoneError"] = "Phone is Required"
            error = true
        }

        if (!error) {
            setIsLoading(true);
            const url = `${base_url}events/${eventName}/rsvp`;
            fetch(url, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "rsvp": rsvp
                    }),
                })
                .then(response => response.json())
                .then(data => {
                    setIsLoading(false);
                    if (data.success) {
                        onSuccess();
                    }
                })
        }
        return errors;
    }

    return (
        <>
            <Nav />
            <EventPageContainer image={event ? null : backgroundImage}>
                {event ?
                    <>
                        <RSVPForm 
                            showRSVPForm={showRSVPForm} 
                            setShowRSVPForm={setShowRSVPForm} 
                            event={event} 
                            handleSubmit={handleRSVP}
                            isLoading={isLoading}
                            isSuccess={isSuccess}
                                />
                        <Event event={event} showRSVPForm={() => setShowRSVPForm(true)} />
                    </> :
                    <EventForm handleSubmit={updateEvent} />}
            </EventPageContainer>
        </>
    )
}

export default EventPage;