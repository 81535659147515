import { useContext, useEffect, useState } from "react";
import styled, { createGlobalStyle  } from 'styled-components';

import Nav from "../components/Nav";
import AppContext from "../contexts/AppContext";


const ImageGridDiv = styled.div`
    margin-top: 10vh;
    display: flex;
    flex-direction; row;
    flex-wrap: wrap; 
    align-items: center;
    justify-content: center;
`;

const Image = styled.img`
    width: 40%;
    height: auto;
    margin: 1%;

    @media (max-width: 480px) {
        width: 90%;
    }
`;

const GlobalStyle = createGlobalStyle`
  body {
    overflow: ${props => props.image === null ? "visible" : "hidden" } 
  }
`;

const ModalDiv = styled.div`
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);;
    z-index: 2;
    position: absolute;
    top: ${props => `${parseInt(props.modalOffset)}px`};
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalImage = styled.img`
    height: 90%;
    width: auto;
`;

const PAGE = "gallery"

const GalleryPage = () => {
    const [image, setImage] = useState(null);
    const [images, setImages] = useState([]);

    const {currentPage, updateCurrentPage} = useContext(AppContext);

    useEffect(() => {
       const images = [];
       for (let i = 1; i <= 10; ++i) {
            const image = require(`../images/image${i}.jpg`);
            images.push(image); 
       }
       setImages(images);
       if (!currentPage) {
            updateCurrentPage(PAGE);
        }
    }, []);


    const setCurrentImage = (image) => {
        const windowWidth = window.innerWidth;
        if (windowWidth > 640) {
            setImage(image);
        }
    };

    return (
        <>
            <Nav />
            <GlobalStyle image={image} />
            {image ? 
                <ModalDiv modalOffset={window.pageYOffset} onClick={() => setImage(null)}>
                    <ModalImage src={image} />
                </ModalDiv>
            : null }
            <ImageGridDiv image={image}>
                {images.map((image, idx) => {
                    return <Image src={image} onClick={() => setCurrentImage(image)} key={idx} />
                })}
            </ImageGridDiv>
        </>
    )
}

export default GalleryPage;