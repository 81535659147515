import { useState } from 'react';
import AppContext from '../contexts/AppContext';

import config from "../config/config";


const PAGES = ["our-story", "gallery", "gifts", "event"];
const base_url = config.base_url;

const AppProvider = ({ children }) => {
	const [currentPage, setCurrentPage] = useState(undefined);
    const [event, setEvent] = useState(null);


    const updateCurrentPage = (page) => {
        if (PAGES.find(elem => elem === page)) {
            setCurrentPage(page);
        }
    };

    const updateEvent = (eventName) => {
        if (eventName === null) {
            setEvent(null);
        } else {
            const url = `${base_url}events/${eventName}/`;
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        setEvent(data.event);
                    }
                })
        }
    };

    return (
        <AppContext.Provider value={ { currentPage, updateCurrentPage, updateEvent, event } } >
            {children}
        </AppContext.Provider>
    )
}

export default AppProvider;