import EventPage from "./pages/EventPage";
import GalleryPage from "./pages/GalleryPage";
import GiftsPage from "./pages/GiftsPage";
import OurStoryPage from "./pages/OurStoryPage";
import AppProvider from "./providers/AppProvider";
import LandingPage from "./pages/LandingPage"
import { useContext } from "react";
import AppContext from "./contexts/AppContext";




const App = () => {
    const { currentPage } = useContext(AppContext);
    
    let Component;
    switch (currentPage) {
        case "our-story":
            Component = OurStoryPage;
            break;
        case "gallery":
            Component = GalleryPage;
            break;
        case "gifts":
            Component = GiftsPage;
            break;
        case "event":
            Component = EventPage;
            break;
        default:
            Component = LandingPage;
            break;
    }

   return (
        <Component />
   )
}

export default App;