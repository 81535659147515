import { useContext, useEffect, useState } from "react";
import styled from 'styled-components';
import Nav from "../components/Nav";
import AppContext from "../contexts/AppContext";
const backgroundImage = require(`../images/output1.png`);

const PAGE = "gifts"

const ContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    margin-top: 10vh;
    background-image: url(${backgroundImage});
    background-size: cover;
    background-repeant: no-repeat;
`;

const HeaderDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2.5vw;
    text-align: center;
    width: 60%;
    background: white;
    font-weight: 2000;
    padding: 0.35rem;

    @media (max-width:640px){
        width: 90%;
        font-size: 5vw;
    };
`;



const HyperLink = styled.a`
    text-decoration: none;
    color: #063970;
`;


const GiftsPage = () => {


    const {currentPage, updateCurrentPage} = useContext(AppContext);

    if (!currentPage) {
        updateCurrentPage(PAGE);
    }

    return (
        <>
            <Nav />
            <ContainerDiv>
                <HeaderDiv>
                    <h1>Gifts</h1>
                    <p style={{"textAlign": "center"}}>Having your love and support for our elopement is the greatest gift we could ask for! Since we have our special home already, there isn't much we need. However, if you wish to surprise us with a gift, a contribution towards our honeymoon would be most appreciated.</p>
                    <HyperLink target="_blank" href="http://www.hitchd.com/emmaandzachary"><h4>Click here to contribute to our celebration</h4></HyperLink>

                </HeaderDiv>
            </ContainerDiv>
        </>
    )
}

export default GiftsPage;