import { useContext, useState} from "react";
import styled from 'styled-components';

import image from '../images/image11.jpg';

import Nav from "../components/Nav";
import AppContext from "../contexts/AppContext";

const PAGE = "our-story"


const ContainerContentDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    padding: 0% 10%;
    text-align: center;    
    @media (max-width:640px){
        width: 90%;
        padding: 0;
    };
`;


const ContainerDiv = styled.div`
    margin-top: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    background-color: #e3e3e3;
    font-size: 1.5vw;
    @media (max-width:640px){
        flex-direction: column;
        overflow-y: visible;
        margin-top: 0;
        padding: 2.5rem;
        font-size: 5vw;
    };
`;


const ContainerDivHeader = styled.h1`
    @media (max-width:640px){
        display: None;
    };
`;

const ContainerMobileDiv = styled.div`
    color: white;
    margin-top: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    background-color: #e3e3e3;
    background-image: url(${image});
    background-size: cover;
    min-height: 90vh;
    background-repeant: no-repeat;
    @media (min-width:640px){
        flex-direction: column;
        overflow-y: visible;
        display: None
    };
`;


const ContainerImage = styled.img`
    width: 40%;
    min-height: 90vh;
    height: auto;
    @media (max-width:640px){
        display: None;
        width: 100%;
        height: auto;
    };
`;

const OurStoryPage = () => {
    const [initialPageOffest, setInitialPageOffset] = useState(0);

    const { currentPage, updateCurrentPage, } = useContext(AppContext);

    if (!currentPage) {
        updateCurrentPage(PAGE);
    }

    return (
        <>
            <Nav />
            <ContainerMobileDiv>
                <h1>Our Story</h1>
            </ContainerMobileDiv>
            <ContainerDiv>
                <ContainerImage src={image} />
                <ContainerContentDiv>
                    <ContainerDivHeader>Our Story</ContainerDivHeader>
                    <p>
                    Our story began at a high school girls’ basketball game in Clovis,
                CA. Emma coached for a local team and Zachary’s dad helped with
                another team in the same league. One of Emma’s many duties was to
                scout the competition and film opposing teams’ games. When she showed
                up late to the game she was supposed to film, she looked up in the
                bleachers to see that there was only one seat left next to a super
                cute guy in a Lululemon headband (OK, maybe there were a few other
                seats available). Zachary was reluctantly attending the game per his
                dad’s request but was happy to allow Emma to sit next to him. Zachary
                and Emma bonded during the game and let’s just say that Zachary was
                less reluctant to film girls’ basketball games after that in hopes
                that he would see Emma again. After a few more games and a blossoming
                friendship, the rest is history. Over four years later, we are
getting married and blissfully happy!
                    </p>
                </ContainerContentDiv>
            </ContainerDiv>
        </>
    )

};

export default OurStoryPage;