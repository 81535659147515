import { useRef, useState } from "react";
import styled from "styled-components";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


const FormContainer = styled.div`
    background-color: white;
    padding: 2.5vh 2.5vw;
    border-radius: 5%;
`;

const StyledModalInput = styled(Form.Control)`
    text-align: center;

`

const StyledForm = styled(Form)`
    text-align: center;
`;


const EventForm = ({ handleSubmit }) => {
    const [showError, setShowError] = useState(false);
    const eventInputRef = useRef();

    const onSubmit = (e) => {
        e.preventDefault();
        const success = handleSubmit(eventInputRef.current.value);
        setShowError(success);
    }

    return (
        <FormContainer>
            <StyledForm onSubmit={onSubmit}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Event Name</Form.Label>
                    <Form.Control type="text" ref={eventInputRef} />
                </Form.Group>
                {showError ? <p style={{color: "red"}}>Event does no exist.</p>: null}
                <Button variant="primary" type="submit">
                    Enter Event
                </Button>
            </StyledForm>
        </FormContainer>
    );
}

export default EventForm;