import { useRef, useState } from "react";
import styled from "styled-components";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

const AdditionalContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem 0;

    @media (max-width:640px){
        flex-wrap: wrap;
    };
`;



const SpinnerBackgroundDiv = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    min-width: 100vw;
    min-height: 100vh;
    background-color: black;
    opacity: 40%;
`;



const SuccessBackgroundDiv = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    min-width: 100vw;
    min-height: 100vh;
`;

const StyledFormControl = styled(Form.Control)`
    display: inline-block;
    width: 90%;
`;


const StyledFormButton = styled(Button)`
    border-radius: 50%;
    font-size: .750rem;
`;


const FormButton = styled(Button)`
    width: 45%;
    margin: 2%;
    @media (max-width:640px){
        flex-wrap: wrap;
        width: 100%;
    };
`;


const FormContainer = styled.div`
    background-color: white;
    padding: 2.5vh 2.5vw;
    border-radius: 5%;
`;


const Spinner = styled.div`
    margin: 50px;
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;


const RSVPForm = ({ event, handleSubmit, showRSVPForm, setShowRSVPForm, isLoading, isSuccess }) => {
    const [errors, setErrors] = useState({});
    const [party, setParty] = useState([]);
    const nameInputRef = useRef();
    const emailInputRef = useRef();
    const phoneInputRef = useRef();
    const additionalInfoInputRef = useRef();


    const onSubmit = (e) => {
        e.preventDefault();
        const errors = handleSubmit({
            name: nameInputRef.current.value,
            email: emailInputRef.current.value,
            phone: phoneInputRef.current.value,
            party: party,
            additionalInfo:  additionalInfoInputRef.current.value
        }, event.code);
        setErrors(errors);
    }

    const updateParty = (value, idx) => {
        const partyCopy = [...party];
        partyCopy[idx] = value;
        setParty(partyCopy);
    }

    const addParty = () => {
        setParty([...party, ""]);
    }

    const removeParty = (removeIndex) => {
        const partyCopy = party.filter((_, index) => removeIndex !== index);
        setParty(partyCopy);
    }

    if (isLoading) {
        return (
            <SpinnerBackgroundDiv>
                <Spinner />
            </SpinnerBackgroundDiv>
        );
    } else {
        return (
            <Modal
            show={showRSVPForm}
            onHide={() => setShowRSVPForm(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                {!isSuccess ? 
                    <>
                <h1>{event.name} RSVP Form</h1>
                <Form onSubmit={onSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Name</Form.Label>
                        <Form.Control ref={nameInputRef} type="text" placeholder="Name" />
                        {errors.nameError ? <p style={{color: "red"}}>{errors.nameError}</p>: null}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control ref={emailInputRef} type="email" placeholder="Email" />
                        {errors.emailError ? <p style={{color: "red"}}>{errors.emailError}</p>: null}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control ref={phoneInputRef} type="phone" placeholder="Phone Number" />
                        {errors.phoneError ? <p style={{color: "red"}}>{errors.phoneError}</p>: null}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Add additional people (Members of Party) <div onClick={addParty} style={{"color": "green", "display": "inline", "fontSize": "xx-large"}}>+</div></Form.Label>
                        {party.map((person, idx) => {
                            return (
                                <AdditionalContainer>
                                    <StyledFormControl value={person} onChange={(e) => {updateParty(e.target.value, idx)}} type="text" placeholder="Name" /> 
                                    <StyledFormButton onClick={() => removeParty(idx)} variant="danger" size="sm">X</StyledFormButton>
                                </AdditionalContainer>
                            );
                        })}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Additional Information</Form.Label>
                        <Form.Control ref={additionalInfoInputRef} as="textarea" rows="7" placeholder="Dietary Restrictions, Accomodation, etc." />
                    </Form.Group>
                    <FormButton variant="primary" type="submit">
                        Submit
                    </FormButton>
                    <FormButton variant="danger" onClick={() => setShowRSVPForm(false)}>
                        Cancel
                    </FormButton>
                </Form>
                    </> : 
                            <div style={{width: "100%", "textAlign": "center", "padding": "2vh 2vw"}}>
                                <h1>Your RSVP has been Received for the {event.name}</h1>
                            </div>
                }
            </Modal.Body>
        </Modal>
        );
    }
}

export default RSVPForm;