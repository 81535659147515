const environments = {
    production: {
        url: "https://emma-and-zac.herokuapp.com/",
        name: "Production"
    },
    local: {
        url: "http://localhost:8000/",
        name: "Local"
    },
  }
  
  let env = 'production'; // update this to change environment
  
  let config = {
    base_url: environments[env].url,
    environment: environments[env],
  };
  
  export default config;