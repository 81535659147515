import { useContext, useEffect, useState } from "react";
import styled from 'styled-components';



import AppContext from "../contexts/AppContext";

const backgroundImage = require(`../images/image15.jpeg`);

const BackgroundImageDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${ props => props.image });
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100% 100%;
    min-width: 100vw;
    min-height: 100vh;
`;


const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border: 0.10rem solid white;
    padding: 0.50rem 1rem;

    &:hover {
        color: #e3e3e3;
        background-color: #ffffff
    }
`;


const LandingPage = () => {
    const { updateCurrentPage } = useContext(AppContext);

    return (
        <>
            <BackgroundImageDiv image={backgroundImage}>
                <Button onClick={() => updateCurrentPage('our-story')}>
                    Enter
                </Button>
            </BackgroundImageDiv>
        </>
    );
}


export default LandingPage;